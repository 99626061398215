<template>
  <div class="special-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses'}">教学内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses/special'}">专题管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加专题</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="pageForm" :rules="pageRules" ref="pageRef" label-width="auto" class="page-form">
        <el-form-item label="归属校区" prop="tid_campus">
          <el-select v-model="pageForm.tid_campus" placeholder="请选择归属校区">
            <el-option :label="item.title" :value="item.m_id" v-for="item in campusList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专题标题" prop="title">
          <el-input v-model="pageForm.title"></el-input>
        </el-form-item>
        <el-form-item label="显示分类数量" prop="block_num">
          <el-input-number size="small" v-model="pageForm.block_num" :step="1" :min="1" :max="5"></el-input-number>
        </el-form-item>
        <el-form-item label="专题图标图" prop="icon">
          <upload :url="pageForm.icon" :filed="'icon'" :max-num="1" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item label="背景图" prop="background">
          <upload :url="pageForm.background" :filed="'background'" :max-num="1" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">立即创建</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../common/Upload'
export default {
  name: 'SpecialAdd',
  components: {
    Upload
  },
  data () {
    return {
      campusList: [],
      pageForm: {
        tid_campus: '',
        title: '',
        block_num: 4,
        icon: '',
        background: ''
      },
      pageRules: {
        tid_campus: [
          { required: true, message: '请选择归属校区', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写专题标题', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请上传专题图标图', trigger: 'blur' }
        ],
        background: [
          { required: true, message: '请上专题背景图', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getCampusList()
  },
  methods: {
    // 获取校区列表
    async getCampusList () {
      const { data: res } = await this.$http.get('/campus-list')
      if (res.code === 200) {
        this.campusList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    submit () {
      this.$refs.pageRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/special', this.pageForm)
          if (res.code === 200) {
            this.$message.success(res.msg)
            await this.$router.push('/admin/content/courses/special')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    reset () {
      this.$refs.pageRef.resetFields()
    },
    // 上传组件返回值
    checkedFile (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delFile (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.page-form{
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}
</style>
